import React from "react";
import { View, Text } from "react-native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import Home from "./Home";
import { BlurView } from "expo-blur";
import { createStackNavigator } from "@react-navigation/stack";
import Subscription from "./Subscription";
import { useQuery } from "@apollo/client";
import { Albums, subscription } from "../types";
import { GET_SUBSCRIPTIONS } from "../Query/Account";
import { useEffect } from "react";
import { useNavigation } from "@react-navigation/native";
import AlbumCreate from "./AlbumCreate";
import Album from "./Album";
import Video from "./Video";
import CreateVideo from "./VideoCreate";
import Watch from "./Watch";
import MyAccount from "./MyAccount";
import VideoEdit from "./VideoEdit";
import TagView from "./TagView";
import ShareCenter from "./ShareCenter";
import BatchShare from "./BatchShareScreen";
import NewCheckout from "./NewCheckout";
import AccountVerify from "./AccountVerify";

const Stack = createStackNavigator();
export default function Core() {
  const MySubscriptions = useQuery<
    {
      mySubscriptions: subscription[];
      myAlbums: Albums[];
      myAccount: {
        verified: boolean;
      };
    },
    never
  >(GET_SUBSCRIPTIONS, {
    fetchPolicy: "network-only",
  });

  const navigation = useNavigation();
  useEffect(() => {
    if (
      MySubscriptions?.data?.mySubscriptions?.filter(
        (sub) => sub.status == "ACTIVE"
      )?.length == 0
    ) {
      if (localStorage.getItem("skipSubscription") == "true") {
      } else {
        console.log("No active subscriptions");
        if (localStorage.getItem("idToken") != null) {
          navigation.navigate("Subscription", {});
          return;
        } else {
          navigation.navigate("LogIn", {});
          return;
        }
      }
    }
    if (MySubscriptions?.data?.myAccount?.verified == false) {
      navigation.navigate("AccountVerify", {});
      return;
    } else {
      if (window.location.href.includes("AccountVerify")) {
        window.location.href = "/core";
      }
    }
  }, [MySubscriptions]);
  return (
    <Stack.Navigator
      detachInactiveScreens={true}
      screenOptions={{
        headerStyle: {
          backgroundColor: "#334155",
          borderBottomColor: "#64748b",
        },
        headerTintColor: "#f7790a",
      }}
      initialRouteName="Subscription"
    >
      <Stack.Screen
        name="Home"
        component={Home}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Subscription"
        component={NewCheckout}
        options={{ headerShown: false, presentation: "modal" }}
      />
      {/* <Stack.Screen
        name="Upgrade"
        component={NewCheckout}
        options={{ headerShown: false, presentation: 'modal' }}
      /> */}
      <Stack.Screen
        name="CreateAlbum"
        component={AlbumCreate}
        options={{ presentation: "card", title: "Create New Album" }}
      />
      <Stack.Screen name="Album" component={Album} />

      <Stack.Screen name="Video" component={Video} />
      <Stack.Screen name="CreateVideo" component={CreateVideo} />
      <Stack.Screen name="Watch" component={Watch} />
      <Stack.Screen name="MyAccount" component={MyAccount} />
      <Stack.Screen name="EditVideo" component={VideoEdit} />
      <Stack.Screen name="Tag" component={TagView} />
      <Stack.Screen name="Tags" component={Home} />
      <Stack.Screen name="ShareCenter" component={ShareCenter} />
      <Stack.Screen name="Share" component={BatchShare} />
    </Stack.Navigator>
  );
}
