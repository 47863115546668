import {
  FlatList,
  Heading,
  HStack,
  IconButton,
  Link,
  Image,
  Text,
  View,
  VStack,
} from 'native-base'
import React, { useState } from 'react'
import { Albums } from '../../types'
import Subscription from '../Subscription'
import { useEffect } from 'react'
import Ionicons from '@expo/vector-icons/Ionicons'
import { useNavigation } from '@react-navigation/native'
import FastImage from 'react-native-fast-image'
import { TouchableOpacity, useWindowDimensions } from 'react-native'
import { getWidthDividedBy } from '../../helper'

export default function AlbumsList(props: {
  albums?: Albums[] | undefined
  headerContent?: React.ReactNode
}) {
  const listRef = React.useRef<FlatList<Albums> | null>(null)
  const [scrollIndex, setScrollIndex] = useState(0)
  function scrollForward() {
    listRef.current?.scrollToIndex({ index: scrollIndex + 1 })
    setScrollIndex(scrollIndex + 1)
  }
  function scrollBackward() {
    listRef.current?.scrollToIndex({
      index: scrollIndex - 0.5 < 0 ? 0 : scrollIndex - 1,
    })
    setScrollIndex(scrollIndex - 0.5 < 0 ? 0 : scrollIndex - 1)
  }
  const navigation = useNavigation()
  const width = useWindowDimensions().width

  const column = getWidthDividedBy(width)

  console.log(column)
  return (
    <>
      <FlatList
        flex={1}
        ListHeaderComponent={
          <>
            {props.headerContent}
            <Heading padding={2} size="lg">
              {'Albums'}
              <Ionicons
                onPress={() => navigation.navigate('CreateAlbum')}
                flex={1}
                style={{ position: 'absolute', right: 15 }}
                size={32}
                name="md-add-outline"
              ></Ionicons>
            </Heading>
          </>
        }
        key={column.toString()}
        numColumns={column}
        keyExtractor={(item, i) => item.id + i}
        width="100%"
        // horizontal={true}
        showsHorizontalScrollIndicator={false}
        ref={listRef}
        onEndReached={() => {}}
        data={props.albums}
        renderItem={(item) => (
          <TouchableOpacity
            flex={4}
            onPress={() => {
              console.log(item.item.id)
              navigation.navigate('Album', { albumId: item.item.id })
            }}
          >
            <View
              margin={'5px'}
              borderRadius={10}
              height={240}
              width={(window.window.innerWidth - 13 * column) / column}
              backgroundColor={'blueGray.700'}
            >
              <Image
                resizeMode={'cover'}
                borderRadius={10}
                alt={'video thumbnail'}
                style={{ width: '100%', height: '100%', overflow: 'hidden' }}
                source={{
                  uri: item.item.coverPhoto?.url || undefined,
                }}
              />
              <View
                borderBottomRadius={10}
                paddingX={2}
                width="100%"
                backgroundColor={'white'}
                opacity={0.8}
                style={{ position: 'absolute', bottom: 0 }}
              >
                <Text color={'black'}>{item.item.title}</Text>
                <Text
                  color={'black'}
                  paddingRight={2}
                  style={{ position: 'absolute', right: 0 }}
                >
                  Shared By: {item.item.owner.firstName}
                </Text>
              </View>
            </View>
          </TouchableOpacity>
        )}
      ></FlatList>
    </>
  )
}
