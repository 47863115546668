import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { useNavigation, useRoute } from '@react-navigation/native'
import {
  View,
  Text,
  ScrollView,
  Center,
  Button,
  TextField,
  AlertDialog,
  IconButton,
  Select,
} from 'native-base'
import React from 'react'
import {
  DELETE_VIDEO,
  GET_TAGS,
  TAG_VIDEO,
  UNTAG_VIDEO,
  VIEW_VIDEO,
} from '../Query/Video.graphql'
import moment from 'moment'
import { TouchableOpacity } from 'react-native'
import Ionicons from '@expo/vector-icons/Ionicons'
import { Tags } from '../types'
import { GET_ALBUM } from '../Query/Album.graphql'

const VideoEdit = () => {
  const route = useRoute()
  const [isOpen, setIsOpen] = React.useState(false)
  const [isOpenAddTag, setIsOpenAddTag] = React.useState(false)
  const [createNewTag, setCreateNewTag] = React.useState(false)
  const [TagToLink, setTagToLink] = React.useState(undefined)
  const onClose = () => setIsOpen(false)
  const client = useApolloClient()
  const cancelRef = React.useRef(null)
  const navigation = useNavigation()
  const Video = useQuery(VIEW_VIDEO, {
    variables: { id: route.params.id },
  })
  const tags = useQuery(GET_TAGS, {
    skip: isOpenAddTag === false,
  })
  const [UntagVideo] = useMutation(UNTAG_VIDEO)
  const [TagVideo] = useMutation(TAG_VIDEO, {
    variables: { videoId: route.params.id },
  })
  const [DeleteVideo] = useMutation(DELETE_VIDEO, {
    variables: { deleteVideoId: route.params.id },
  })
  if (route.params.id === undefined) return <Text>Video not found</Text>
  if (Video.loading)
    return (
      <Center flex={1}>
        <Text>Loading...</Text>
      </Center>
    )
  console.log(Video.data)
  return (
    <View
      padding={'2'}
      style={{ height: '100%' }}
      _dark={{ bg: 'blueGray.900' }}
    >
      <ScrollView flex={1} _dark={{ bg: 'blueGray.900' }}>
        <View>
          <Text fontSize={'2xl'}>{Video.data?.video.title}</Text>
        </View>
        <View flex={1}>
          <Text>Title</Text>
          <TextField title={'Title'} defaultValue={Video.data?.video.title} />
          <Text>Description</Text>

          <TextField
            title={'Description'}
            defaultValue={Video.data?.video.description}
          />
          <View flexDirection={'row'}>
            <Text fontSize={'2xl'}>Tags</Text>
            <Ionicons
              onPress={() => {
                setIsOpenAddTag(true)
              }}
              style={{ position: 'absolute', right: 10 }}
              color={'white'}
              size={35}
              name="md-add-outline"
            ></Ionicons>
          </View>
          <View
            _dark={{ bg: 'blueGray.700' }}
            _light={{ bg: 'gray.200' }}
            borderRadius={10}
            paddingY={5}
          >
            {Video?.data?.video?.tags.length > 0 ? (
              Video?.data?.video?.tags?.map((tag) => (
                <View
                  key={tag.id}
                  paddingY={1}
                  paddingX={3}
                  borderBottomColor={'blueGray.900'}
                  borderBottomWidth={1}
                  marginX={2}
                  flexDirection={'row'}
                >
                  <Text fontSize={'md'}>{tag.name}</Text>
                  <Ionicons
                    onPress={async () => {
                      console.log({
                        videoID: route.params.id,
                        tagID: tag.id,
                      })
                      await UntagVideo({
                        variables: {
                          video: route.params.id,
                          tag: tag.id,
                        },
                      })
                      await Video.refetch()
                    }}
                    // onPress={() => navigation.navigate('CreateAlbum')}
                    style={{ position: 'absolute', right: 10 }}
                    color={'white'}
                    size={20}
                    name="md-trash-outline"
                  ></Ionicons>
                </View>
              ))
            ) : (
              <Center>
                <Text fontSize={'xl'}>No Tags</Text>
              </Center>
            )}
          </View>
          <View flexDirection={'row'}>
            <Text>
              Published:{' '}
              {moment.unix(Video.data?.video.createdAt / 1000).fromNow()}
            </Text>
          </View>
        </View>
        <AlertDialog
          leastDestructiveRef={cancelRef}
          isOpen={isOpen}
          onClose={onClose}
        >
          <AlertDialog.Content>
            <AlertDialog.CloseButton />
            <AlertDialog.Header>Delete Video</AlertDialog.Header>
            <AlertDialog.Body>
              Are you sure? You can't undo this action afterwards.
            </AlertDialog.Body>
            <AlertDialog.Footer>
              <Button.Group space={2}>
                <Button
                  variant="unstyled"
                  colorScheme="coolGray"
                  onPress={onClose}
                  ref={cancelRef}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="danger"
                  onPress={() => {
                    //TODO: Delete Video
                    DeleteVideo()
                    setTimeout(() => {
                      client.refetchQueries({
                        include: [GET_ALBUM],
                      })
                    }, 670)
                    navigation.navigate('Album', {
                      albumId: Video.data?.video.albumID,
                    })
                    onClose()
                  }}
                >
                  Delete
                </Button>
              </Button.Group>
            </AlertDialog.Footer>
          </AlertDialog.Content>
        </AlertDialog>
        <AlertDialog
          leastDestructiveRef={cancelRef}
          isOpen={isOpenAddTag}
          onClose={() => {
            setIsOpenAddTag(false)
            setCreateNewTag(false)
          }}
        >
          <AlertDialog.Content>
            <AlertDialog.CloseButton />
            <AlertDialog.Header>Tag Video</AlertDialog.Header>
            <AlertDialog.Body>
              {createNewTag === false ? (
                <Select
                  onValueChange={(value) => {
                    if (value === 'NEW') {
                      setCreateNewTag(true)
                    } else {
                      setTagToLink(value)
                    }
                  }}
                >
                  <Select.Item label="Create New" value="NEW" />
                  {tags.data?.getTags ? (
                    tags.data?.getTags
                      ?.filter(
                        (item: Tags, i: Number, arr: Tags[]) =>
                          arr.findIndex((t) => t.name === item.name) === i,
                      )
                      .map((tag) => (
                        <Select.Item
                          label={tag.name}
                          value={tag.id}
                        ></Select.Item>
                      ))
                  ) : (
                    <Select.Item label="No Tags" value={undefined} />
                  )}
                </Select>
              ) : (
                <TextField
                  onValueChange={(value) => {
                    setTagToLink(value)
                  }}
                  placeholder="Tag Name"
                ></TextField>
              )}
            </AlertDialog.Body>
            <AlertDialog.Footer>
              <Button.Group space={2}>
                <Button
                  variant="unstyled"
                  colorScheme="coolGray"
                  onPress={() => {
                    setIsOpenAddTag(false)
                    setCreateNewTag(false)
                  }}
                  ref={cancelRef}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="success"
                  onPress={async () => {
                    //TODO: Set Tag
                    console.log(TagToLink)
                    if (TagToLink) {
                      await TagVideo({
                        variables: {
                          videoId: route.params.id,
                          tagName: tags.data.getTags.find(
                            (t) => t.id === TagToLink,
                          ).name,
                        },
                      })
                    }
                    await Video.refetch()
                    await tags.refetch()
                    setIsOpenAddTag(false)
                    setCreateNewTag(false)
                  }}
                >
                  Tag Video
                </Button>
              </Button.Group>
            </AlertDialog.Footer>
          </AlertDialog.Content>
        </AlertDialog>
      </ScrollView>
      <Button
        marginTop={'auto'}
        bgColor={'red.500'}
        onPress={() => {
          setIsOpen(true)
        }}
      >
        Delete Video
      </Button>
    </View>
  )
}

export default VideoEdit
