import { useQuery, useApolloClient } from '@apollo/client'
import { VStack, Text, Progress, HStack, View, Center } from 'native-base'
import { GET_HOME } from '../Query/Account'
import { Albums, subscription } from '../types'

export default function MyAccount() {
  const MyAccount = useQuery<
    {
      myAccount: {
        firstName: string
        lastName: string
        customerId: string
        usedStorage: number
      }
      myAlbums: Albums[]
      mySubscriptions: subscription[]
    },
    never
  >(GET_HOME, { pollInterval: 120000 })
  const apollo = useApolloClient()
  let totalStorage =
    (MyAccount?.data?.mySubscriptions
      ?.filter((sub) => sub.status == 'ACTIVE')
      ?.map((sub) => sub.maxStorage)
      ?.reduce((a, b) => a + b, 0) ?? 0) *
    1024 *
    1024 *
    1024
  console.log(totalStorage)
  if (MyAccount.loading) {
    return <Text>Loading</Text>
  }
  console.log((MyAccount?.data?.myAccount?.usedStorage ?? 0) / totalStorage)
  return (
    <VStack
      _dark={{ bg: 'blueGray.900' }}
      _light={{ bg: 'blueGray.50' }}
      px={4}
      flex={1}
      space={5}
    >
      <HStack>
        <Text fontSize={'2xl'}>Storage Used:</Text>
        <View flex={1}>
          <Progress
            style={{ marginTop: 'auto', marginBottom: 'auto', marginLeft: 10 }}
            size="lg"
            value={
              100 *
              ((MyAccount?.data?.myAccount?.usedStorage ?? 0) / totalStorage)
            }
          />
        </View>
        <Text fontSize={'2xl'} marginLeft={5}>
          {(
            MyAccount?.data?.myAccount?.usedStorage /
            (1024 * 1024 * 1024)
          ).toFixed(2)}
          GB / {(totalStorage / (1024 * 1024 * 1024)).toFixed(0)} GB
        </Text>
      </HStack>
    </VStack>
  )
}
