import { gql } from '@apollo/client'

export const GET_TAGS = gql`
  query GetTags($name: String!) {
    getTags {
      id
      name
    }
  }
`

export const GetTagInfo = gql`
  query GetTags($name: String!) {
    videosByTagName(name: $name) {
      id
      title
      description
      status
      videoLinks {
        thumbnailURL
        previewURL
        videoURL
      }
      owner {
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
`
