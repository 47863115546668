import React, { useState } from 'react'
import {
  View,
  Text,
  HStack,
  Input,
  Button,
  Center,
  Spinner,
  TextField,
  Select,
  Alert,
  AlertDialog,
} from 'native-base'
import { useNavigation, useRoute } from '@react-navigation/native'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { CREATE_ALBUM, MY_ALBUMS } from '../Query/Album.graphql'
import { GET_HOME } from '../Query/Account'
import { VIEW_VIDEO, CREATE_VIDEO } from '../Query/Video.graphql'
import { TouchableOpacity, FlatList, Platform } from 'react-native'
import Ionicons from '@expo/vector-icons/Ionicons'
import * as DocumentPicker from 'expo-document-picker'
import MultiSelect from 'react-native-multiple-select'
import { BlobServiceClient } from '@azure/storage-blob'
import { DocumentResult } from 'expo-document-picker'
import { Albums } from '../types'
import { Link } from 'native-base'
export default function CreateVideo() {
  const [title, setTitle] = useState('')
  const [newTag, setNewTag] = useState('')
  const [description, setDescription] = useState('')
  const navigation = useNavigation()
  const [uploading, setUploading] = useState(false)
  const route = useRoute()
  const client = useApolloClient()
  const [newVideos, setNewVideos] = useState<
    {
      ID: number
      title: string
      album?: string
      description: string
      uploaded: boolean
      tags: string[]
      progress?: number
      file: File | undefined
    }[]
  >([])
  const [isOpen, setIsOpen] = React.useState(-1)

  const onClose = () => setIsOpen(-1)

  const cancelRef = React.useRef(null)
  const MyAlbums = useQuery<{ myAlbums: Albums[] }, never>(MY_ALBUMS)
  const [CreateVideo, { data, loading, error }] = useMutation(CREATE_VIDEO)
  console.log(MyAlbums.data?.myAlbums.flatMap((item) => item.tags))
  return (
    <View
      _dark={{ bg: 'blueGray.900' }}
      _light={{ bg: 'blueGray.50' }}
      flex={1}
      padding={2}
    >
      <FlatList
        keyExtractor={(item, index) => item.ID.toString()}
        style={{
          shadowOffset: { width: 0, height: 0 },
          shadowRadius: -5,
          marginVertical: 10,
        }}
        showsVerticalScrollIndicator={false}
        data={newVideos}
        ListFooterComponent={() => (
          <TouchableOpacity
            onPress={async () => {
              const isSafari =
                /constructor/i.test(window.HTMLElement) ||
                (function (p) {
                  return p.toString() === '[object SafariRemoteNotification]'
                })(
                  !window['safari'] ||
                    (typeof safari !== 'undefined' && safari.pushNotification),
                )

              if (Platform.OS === 'web' && isSafari) {
                alert('Safari is not supported')
              } else {
                const test = await window.showOpenFilePicker({ multiple: true })
                console.log(test)
                let newVideoCopy = [...newVideos]
                for (const file of test) {
                  const newFile = await file.getFile()
                  console.log(newFile)
                  newVideoCopy.push({
                    title: file.name.split('.')[0],
                    description: '',
                    uploaded: false,
                    file: newFile,
                    album: route.params?.albumId,
                    ID: newVideoCopy.length + 1,
                  })
                }
                console.log(newVideoCopy)
                setNewVideos(newVideoCopy)
              }
            }}
          >
            <View
              backgroundColor={'blueGray.700'}
              padding={2}
              borderTopColor={'gray.500'}
              borderTopWidth={1}
              borderBottomRadius={10}
            >
              <Center>
                <HStack>
                  <Center>
                    <Ionicons
                      flex={1}
                      size={24}
                      color={'white'}
                      name="md-add-outline"
                    ></Ionicons>
                  </Center>
                  <Text fontSize={'md'}>{'Add Item'}</Text>
                </HStack>
              </Center>
            </View>
          </TouchableOpacity>
        )}
        renderItem={(data) => {
          console.log(data.item)
          return (
            <View
              key={data.item.ID}
              backgroundColor={'blueGray.800'}
              padding={4}
              borderTopRadius={data.index === 0 ? 10 : 0}
              borderBottomColor={'gray.500'}
              borderBottomWidth={1}
            >
              <HStack>
                <Center>
                  <HStack>
                    {data.item.uploaded === false &&
                      data.item.progress == 50 && (
                        <Spinner
                          size={'sm'}
                          color={'white'}
                          style={{ paddingRight: 8 }}
                        />
                      )}
                    <TouchableOpacity
                      onPress={async () => {
                        const test = await window.showOpenFilePicker({
                          multiple: true,
                        })
                        let file = await test[0].getFile()
                        setNewVideos(
                          newVideos.map((item, index) => {
                            if (index === data.index) {
                              return { ...item, file: file }
                            } else {
                              return item
                            }
                          }),
                        )
                      }}
                    >
                      <HStack>
                        <Ionicons
                          size={24}
                          color={'white'}
                          name={
                            data.item.uploaded === true
                              ? 'ios-cloud-done'
                              : data.item.file
                              ? 'ios-cloud-upload-sharp'
                              : 'ios-cloud-upload-outline'
                          }
                        ></Ionicons>
                        <Text marginLeft={2} fontSize={'md'}>
                          {data.item.uploaded === true
                            ? 'Uploaded'
                            : data.item.file
                            ? 'File Selected'
                            : 'Select File'}
                        </Text>
                      </HStack>
                    </TouchableOpacity>
                  </HStack>
                </Center>
                <Center>
                  {/*@ts-ignore*/}
                  <TextField
                    value={data.item.title}
                    isRequired={true}
                    errorMessage={'Title is required'}
                    color={'white'}
                    placeholderTextColor={'grey.100'}
                    marginLeft={4}
                    placeholder={'Title'}
                    onChangeText={(text) =>
                      setNewVideos(
                        newVideos.map((item, index) => {
                          if (index === data.index) {
                            return { ...item, title: text }
                          } else {
                            return item
                          }
                        }),
                      )
                    }
                    fontSize={'md'}
                  ></TextField>
                </Center>
                <TextField
                  value={data.item.description}
                  color={'white'}
                  placeholderTextColor={'grey.100'}
                  marginX={4}
                  multiline={true}
                  flex={1}
                  onChangeText={(text) =>
                    setNewVideos(
                      newVideos.map((item, index) => {
                        if (index === data.index) {
                          return { ...item, description: text }
                        } else {
                          return item
                        }
                      }),
                    )
                  }
                  placeholder={'Description'}
                  fontSize={'md'}
                ></TextField>
                <Select
                  flex={1}
                  marginRight={30}
                  placeholder={'Album'}
                  selectedValue={data.item.album}
                  onValueChange={(itemValue, itemIndex) => {
                    setNewVideos(
                      newVideos.map((item, index) => {
                        if (index === data.index) {
                          return { ...item, album: itemValue }
                        } else {
                          return item
                        }
                      }),
                    )
                  }}
                >
                  {MyAlbums.data?.myAlbums.map((item, index) => {
                    return <Select.Item label={item.title} value={item.id} />
                  })}
                </Select>
                <Center
                  style={{
                    position: 'absolute',
                    right: 0,
                    bottom: '50%',
                    marginBottom: -12,
                  }}
                >
                  <Ionicons
                    onPress={() => {
                      setNewVideos(
                        newVideos.filter((item, index) => {
                          return index !== newVideos.indexOf(data.item)
                        }),
                      )
                    }}
                    size={24}
                    style={{ paddingLeft: 8 }}
                    color={'white'}
                    name={'ios-trash-outline'}
                  ></Ionicons>
                </Center>
              </HStack>
              <HStack>
                <MultiSelect
                  style={{ backgroundColor: 'blueGray.800', flex: 1 }}
                  items={
                    MyAlbums.data?.myAlbums.flatMap((item) => item.tags) ?? []
                  }
                  uniqueKey="name"
                  value={data.item.tags}
                  onSelectedItemsChange={(text) => {
                    console.log(data.item.tags)
                    setNewVideos(
                      newVideos.map((item, index) => {
                        if ((item.tags ?? []).indexOf(text[0]) > -1) {
                          return {
                            ...item,
                            tags: item.tags.filter((t) => {
                              return t !== text[0]
                            }),
                          }
                        } else if (index === data.index) {
                          return {
                            ...item,
                            tags: [...(item.tags ?? []), ...text],
                          }
                        } else {
                          return item
                        }
                      }),
                    )
                  }}
                />
                <Center>
                  <Ionicons
                    size={24}
                    color={'white'}
                    onPress={() => {
                      setIsOpen(data.index)
                    }}
                    name={'ios-add-circle-outline'}
                  />
                </Center>

                <AlertDialog
                  leastDestructiveRef={cancelRef}
                  isOpen={isOpen === data.index}
                  onClose={onClose}
                >
                  <AlertDialog.Content>
                    <AlertDialog.CloseButton />
                    <AlertDialog.Header>{'Add Tag'}</AlertDialog.Header>
                    <AlertDialog.Body>
                      <TextField
                        placeholder="Tag Name"
                        onChangeText={(text) => {
                          setNewTag(text)
                        }}
                      ></TextField>
                    </AlertDialog.Body>
                    <AlertDialog.Footer>
                      <Button.Group space={2}>
                        <Button
                          variant="unstyled"
                          colorScheme="coolGray"
                          onPress={onClose}
                          ref={cancelRef}
                        >
                          Cancel
                        </Button>
                        <Button
                          colorScheme="success"
                          onPress={() => {
                            if (newTag == '') {
                              return
                            }
                            setNewVideos(
                              newVideos.map((item, index) => {
                                console.log(data.index)
                                if (index === data.index) {
                                  return {
                                    ...item,
                                    tags: [...(item.tags ?? []), newTag],
                                  }
                                } else {
                                  return item
                                }
                              }),
                            )

                            onClose()
                          }}
                        >
                          Save
                        </Button>
                      </Button.Group>
                    </AlertDialog.Footer>
                  </AlertDialog.Content>
                </AlertDialog>
                <Text>{data.item.tags?.join(', ')}</Text>
                <Center style={{ marginLeft: 'auto' }}>
                  <Link
                    onPress={() => {
                      let tags = data.item.tags
                      setNewVideos((prev) => {
                        return prev.map((item, index) => {
                          return {
                            ...item,
                            tags: tags,
                          }
                        })
                      })
                    }}
                  >
                    <Text>Apply To All</Text>
                  </Link>
                </Center>
              </HStack>
            </View>
          )
        }}
      ></FlatList>
      <Button
        disabled={
          loading === true ||
          newVideos.some(
            (item) =>
              item.title == '' ||
              (item.file == undefined && item.album == undefined),
          ) ||
          uploading === true
        }
        bgColor={
          loading === true ||
          newVideos.some(
            (item) =>
              item.title == '' ||
              (item.file == undefined && item.album == undefined),
          )
            ? 'gray.500'
            : 'primary.500'
        }
        onPress={async () => {
          setUploading(true)
          let videoData = [...newVideos]
          for (const video of newVideos) {
            if (video.file && video.uploaded !== true) {
              let data = await CreateVideo({
                variables: {
                  title: video.title,
                  description: video.description,
                  ext: video.file.name.split('.').pop(),
                  albumId: video.album,
                  tagNames: video.tags,
                  size: video.file.size,
                },
              })

              if (data.data.createVideo.status === 203) {
                alert("You've reached your storage limit.")
                videoData = videoData.map((item) => {
                  if (item.ID === video.ID) {
                    return {
                      ...item,
                      progress: -1,
                      uploaded: false,
                      error: true,
                    }
                  } else {
                    return item
                  }
                })
                setNewVideos(videoData)
              } else {
                console.log(video.file)
                const videoCreated = data.data?.createVideo.video
                let url = videoCreated.uploadURL
                ;(videoData = videoData.map((item) => {
                  if (item === video) {
                    return { ...item, progress: 50 }
                  } else {
                    return item
                  }
                })),
                  setNewVideos(videoData)
                console.log(url.split('/video-in')[0] + '?' + url.split('?')[1])
                const blobServiceClient = new BlobServiceClient(
                  url.split('sv=')[0] + '?' + url.split('net')[1],
                  null,
                )
                const containerClient = blobServiceClient.getContainerClient(
                  'video-in',
                )
                const blockBlobClient = containerClient.getBlockBlobClient(
                  videoCreated.id + '.' + video.file.name.split('.').pop(),
                )
                const uploadBlobResponse = await blockBlobClient.uploadData(
                  video.file,
                )

                // let response = await fetch(url, {
                //   method: 'PUT',
                //   body: video.file,
                //   headers: {
                //     'x-ms-blob-type': 'BlockBlob',
                //   },
                // })
                setNewVideos((prevVideo) => {
                  console.log(prevVideo)
                  let newArray = videoData.map((item) => {
                    if (item.ID === video.ID) {
                      return { ...item, progress: 100, uploaded: true }
                    } else {
                      return item
                    }
                  })
                  videoData = newArray
                  if (newArray.every((item) => item.uploaded === true)) {
                    navigation.goBack()
                  }
                  return newArray
                })
              }
            }
          }
          setUploading(false)
        }}
        marginTop={2}
        endIcon={loading ? <Spinner /> : undefined}
      >
        {`Start Upload Video${newVideos.length > 1 ? 's' : ''}`}
      </Button>
    </View>
  )
}
function dataURItoBlob(dataURI) {
  console.log(dataURI)
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ia], { type: mimeString })
}
