import { gql } from '@apollo/client'

export const GET_SHARES = gql`
  query AdminAlbums {
    adminAlbums {
      sharedUsers {
        profile {
          firstName
          lastName
        }
        type
      }
      title
    }
  }
`
