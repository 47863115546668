import React from 'react'
import { View, Text } from 'react-native'

export default function Video() {
  return (
    <View>
      <Text>Video</Text>
    </View>
  )
}
