import { View, Text, FlatList, HStack } from 'native-base'
import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_SHARES } from '../Query/Shares'
import { Platform, TouchableOpacity } from 'react-native'
import _ from 'lodash'

const ShareCenter = () => {
  const shareData = useQuery(GET_SHARES)
  console.log(shareData)
  return (
    <View
      flex={1}
      _dark={{ bg: 'blueGray.900' }}
      _light={{ bg: 'blueGray.50' }}
    >
      <FlatList
        data={shareData.data?.adminAlbums}
        renderItem={SharedAlbum}
      ></FlatList>
    </View>
  )
}

const SharedAlbum = (props) => {
  const { item } = props
  return (
    <TouchableOpacity>
      <View
        marginX={2}
        backgroundColor={'blueGray.600'}
        borderRadius={10}
        padding={2}
        marginY={1}
      >
        <Text fontSize={Platform.isTV === true ? '2xl' : 'lg'}>
          {item.title}
        </Text>
        {item.sharedUsers.length > 0 ? (
          <View
            backgroundColor={'blueGray.800'}
            borderRadius={10}
            padding={2}
            marginY={1}
          >
            {item.sharedUsers.map((user: any, i: number, arr: any[]) => {
              return (
                <View
                  borderBottomColor={'gray.100'}
                  borderBottomWidth={arr.length - 1 > i ? 1 : 0}
                >
                  <HStack>
                    <Text
                      flex={1}
                      fontSize={Platform.isTV === true ? '2xl' : 'lg'}
                    >
                      {user.profile.firstName} {user.profile.lastName}
                    </Text>
                    <Text fontSize={Platform.isTV === true ? '2xl' : 'lg'}>
                      {_.capitalize(user.type)}
                    </Text>
                  </HStack>
                </View>
              )
            })}
          </View>
        ) : (
          <View
            backgroundColor={'blueGray.800'}
            borderRadius={10}
            padding={2}
            marginY={1}
          >
            <Text fontSize={Platform.isTV === true ? '2xl' : 'lg'}>
              No users shared
            </Text>
          </View>
        )}
      </View>
    </TouchableOpacity>
  )
}

export default ShareCenter
