import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  View,
  Text,
  Image,
  Heading,
  ScrollView,
  IconButton,
  HStack,
} from 'native-base'
import { Rating } from 'react-native-ratings'
import Hls from 'hls.js'
import { useApolloClient, useQuery } from '@apollo/client'
import { RATE_VIDEO, VIEW_VIDEO } from '../Query/Video.graphql'
import { useNavigation, useRoute } from '@react-navigation/native'
import ReactHlsPlayer from 'react-hls-player'
import { useWindowDimensions } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import { Center } from 'native-base'
import useDownloader from 'react-use-downloader'
import { Alert } from 'native-base'
import StarRating from 'react-native-star-rating'

export default function Watch() {
  const route = useRoute()
  const apollo = useApolloClient()
  const playerRef = React.useRef()

  const [isOpenAddTag, setIsOpenAddTag] = useState(false)
  console.log(route.params)
  //   const window = useWindowDimensions()
  const Video = useQuery(VIEW_VIDEO, {
    variables: { id: route.params?.videoId },
  })
  const navigation = useNavigation()
  const [downloading, setDownloading] = useState(false)
  useEffect(() => {
    navigation.setOptions({
      title: `Watch: ${Video.data?.video.title ?? ''}`,
      headerRight: () => (
        <Ionicons
          size={30}
          name="pencil"
          color={'#f7790a'}
          style={{ marginRight: 10, color: '#f7790a' }}
          onPress={() => {
            navigation.navigate('EditVideo', {
              id: route.params?.videoId,
            })
          }}
        />
      ),
      // headerRight: () => (
      //   <View style={{ flexDirection: 'row' }}>
      //     <Ionicons
      //       name="download-outline"
      //       size={30}
      //       color={'#f7790a'}
      //       style={{ marginRight: 10, color: '#f7790a' }}
      //       onPress={() => {
      //         setDownloading(true)
      //         fetch(Video.data?.video.videoLinks.downloadURL, {
      //           method: 'GET',
      //         })
      //           .then((response) => response.blob())
      //           .then((blob) => {
      //             setDownloading(false)
      //             // Create blob link to download
      //             const url = window.URL.createObjectURL(new Blob([blob]))
      //             const link = document.createElement('a')
      //             link.href = url
      //             link.setAttribute(
      //               'download',
      //               Video.data?.video?.filePath
      //                 ? `${
      //                     Video.data.video.title
      //                   }.${Video.data?.video?.filePath.split('.').pop()}`
      //                 : `${Video.data.video.title}.mp4`,
      //             )

      //             // Append to html link element page
      //             document.body.appendChild(link)

      //             // Start download
      //             link.click()

      //             // Clean up and remove the link
      //             link.parentNode.removeChild(link)
      //             // download(
      //             //   Video.data?.video.videoLinks.downloadURL,

      //             // )
      //           })
      //       }}
      //     ></Ionicons>
      //   </View>
      // ),
    })
  }, [Video])
  console.log(Video.data?.video)
  return (
    <View
      _dark={{ bg: 'blueGray.900' }}
      _light={{ bg: 'blueGray.50' }}
      flex={1}
    >
      {downloading && (
        <Alert maxW={800}>
          <HStack>
            <Alert.Icon />
            <Text color="coolGray.800">
              Downloading, Please Wait this can take a few minutes.
            </Text>
          </HStack>
        </Alert>
      )}
      <ReactHlsPlayer
        src={Video.data?.video.videoLinks.videoURL}
        autoPlay={__DEV__ === true ? false : true}
        controls={true}
        style={{ backgroundColor: 'black' }}
        width={'100%'}
        height={
          (window.window.innerWidth * 9) / 16 < window.window.innerHeight
            ? window.window.innerHeight * 0.6
            : window.window.innerHeight * 0.4
        }
        hlsConfig={{
          maxBufferLength: 30,
          maxBufferSize: 1000,
          maxMaxBufferLength: 1000,
          maxMaxBufferSize: 1000,
          maxBufferHole: 0.5,
          maxSeekHole: 2,
          fragLoadingMaxRetry: 10,
          fragLoadingRetryDelay: 500,
          fragLoadingMaxRetryTimeout: 64000,
          fragLoadingLoopThreshold: 3,
          appendErrorMaxRetry: 3,
          xhrSetup: function (xhr: XMLHttpRequest, url: string) {
            console.log('xhr', xhr)
            const urlAppend = Video.data?.video.videoLinks.videoURL.split(
              '?',
            )[1]
            xhr.open(
              'GET',
              `${url}${
                urlAppend && url.includes('?') === false ? `?${urlAppend}` : ''
              }`,
              true,
            )
            // call
          },
        }}
      />
      <View flex={1}>
        <Heading marginLeft={4} marginTop={2}>
          {Video.data?.video.title}
        </Heading>
        <div style={{ width: 120 }}>
          <Rating
            type="custom"
            ratingColor={'#f7790a'}
            tintColor="#0f172a"
            ratingCount={5}
            imageSize={20}
            startingValue={Video.data?.video.rating ?? 0}
            style={{
              marginBottom: 0,
              marginTop: 'auto',
              backgroundColor: '#0f172a',
              marginLeft: 4,
            }}
            onFinishRating={(rating) => {
              console.log(rating)
              apollo.mutate({
                mutation: RATE_VIDEO,
                variables: {
                  videoId: route.params?.videoId,
                  rating: rating,
                },
              })
            }}
          />
        </div>

        <Text marginLeft={4} marginTop={2}>
          {Video.data?.video.description}
        </Text>
        <View marginX={4} flexDirection={'row'}>
          <Text fontSize={'2xl'}>Tags</Text>
        </View>
        <View
          _dark={{ bg: 'blueGray.700' }}
          _light={{ bg: 'gray.200' }}
          borderRadius={10}
          paddingY={5}
          marginX={4}
        >
          {Video?.data?.video?.tags.length > 0 ? (
            Video?.data?.video?.tags?.map((tag) => (
              <View
                key={tag.id}
                paddingY={1}
                paddingX={3}
                borderBottomColor={'blueGray.900'}
                borderBottomWidth={1}
                marginX={2}
                flexDirection={'row'}
              >
                <Text fontSize={'md'}>{tag.name}</Text>
              </View>
            ))
          ) : (
            <Center>
              <Text fontSize={'xl'}>No Tags</Text>
            </Center>
          )}
        </View>
      </View>
    </View>
  )
}
