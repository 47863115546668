import { useApolloClient, useQuery } from "@apollo/client";
import {
  PayPalScriptProvider,
  PayPalButtons,
  FUNDING,
} from "@paypal/react-paypal-js";
import {
  AVAILABLE_SUBSCRIPTIONS,
  CAPTUREPAYMENT,
  GET_SUBSCRIPTIONS,
  changePlan,
} from "../Query/Account";
import { useRef, useState } from "react";
import {
  AspectRatio,
  Badge,
  Box,
  Button,
  Center,
  CheckIcon,
  FlatList,
  FormControl,
  HStack,
  Heading,
  Image,
  Link,
  ScrollView,
  Select,
  Stack,
  Switch,
  Text,
  View,
  WarningOutlineIcon,
} from "native-base";
import { Dimensions, useWindowDimensions } from "react-native";
import Album from "./Album";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { states } from "../config";
import { subscription } from "../types";

function NewCheckout() {
  const apollo = useApolloClient();
  const windowSize = useWindowDimensions();
  const navigation = useNavigation();
  const [selectedPlan, setSelectedPlan] = useState<
    | {
        id: string;
        name: string;
        description: string;
        GB: number;
        maxShares: number;
        priceYearly: number;
        priceMonthly: number;
        trial: boolean;
        paypalPlanMonthlyTest: string;
        paypalPlanYearlyTest: string;
        paypalPlanMonthlyProd: string;
        paypalPlanYearlyProd: string;
      }
    | undefined
  >(undefined);
  const FUNDING_SOURCES = [FUNDING.PAYPAL, FUNDING.CARD];
  const Plans = useQuery<
    {
      availablePlans: {
        id: string;
        name: string;
        description: string;
        GB: number;
        maxShares: number;
        priceYearly: number;
        priceMonthly: number;
        trial: boolean;
        paypalPlanMonthlyTest: string;
        paypalPlanYearlyTest: string;
        paypalPlanMonthlyProd: string;
        paypalPlanYearlyProd: string;
      }[];
    },
    never
  >(AVAILABLE_SUBSCRIPTIONS, {});
  const initialOptions = {
    "client-id": __DEV__
      ? "AWAWnpZgxKUkbhl5P_u_uslmqcfq9IRqb6s1xGLSYlN9KTKEJUFVF4b4oJP56tl9JlWdkF0uXvAzrI80"
      : "Aav5PA17Sw3IIs5dBJ0ZvR4p9OIzMxMSpMMzprVYfsYdB6WgcMskGNx59xZolT3Z1Riamt6kqnAiI649",
    "enable-funding": "paylater,venmo",
    vault: true,
  };
  const MySubscriptions = useQuery<
    {
      mySubscriptions: subscription[];
      myAccount: { id: string; userID: string; email: string };
      myAlbums: { id: string }[];
    },
    never
  >(GET_SUBSCRIPTIONS, {
    fetchPolicy: "cache-and-network",
  });
  console.log("MySubscriptions", MySubscriptions);
  const columns =
    Math.floor(Dimensions.get("window").width / 275) > 0
      ? Math.floor(Dimensions.get("window").width / 275)
      : 1;
  //make a ref for the scroll view
  const scrollRef = useRef();
  const route = useRoute();
  const [priceMonthly, setPriceMonthly] = useState(false);
  const [taxRate, setTaxRate] = useState<any | undefined>(undefined);
  console.log("tee", route);
  if (
    (MySubscriptions?.data?.mySubscriptions?.filter(
      (sub) => sub.status == "ACTIVE"
    )?.length ?? 0) > 0
  ) {
    navigation.navigate("Home");
  }
  return (
    <ScrollView
      ref={scrollRef}
      padding={"2"}
      style={{ height: "100%" }}
      _dark={{ bg: "blueGray.900" }}
    >
      <Center>
        <Heading>Plans</Heading>
        <Link>
          <Text
            underline={true}
            onPress={() => {
              localStorage.setItem("skipSubscription", "true");
              navigation.navigate("Home");
            }}
          >
            Skip, Use only albums shared with me.
          </Text>
        </Link>
      </Center>
      {/* make a grid of all plans with 2 columns */}
      <Center>
        <Button
          onPress={() => {
            setPriceMonthly(!priceMonthly);
          }}
          backgroundColor={"blue.500"}
        >
          {priceMonthly === true ? "Switch To Yearly" : "Switch To Monthly"}
        </Button>
      </Center>
      <View
        style={{
          flexDirection: windowSize.width > 400 ? "row-reverse" : undefined,
        }}
      >
        <View
          paddingX={2}
          paddingY={2}
          style={{
            flex: 1,
          }}
        >
          <FlatList
            key={columns}
            //@ts-ignore
            data={Plans?.data?.availablePlans}
            numColumns={columns}
            renderItem={(data) => (
              <View width={`${100 / columns}%`} padding={1}>
                <Box
                  rounded="lg"
                  overflow="hidden"
                  borderColor="coolGray.200"
                  borderWidth="5"
                  _dark={{
                    borderColor: MySubscriptions.data?.mySubscriptions
                      .filter((a) => a.status === "ACTIVE")
                      .some((j) => j.planID === data?.item.id)
                      ? "success.500"
                      : "coolGray.600",
                    borderWidth: 3,
                    backgroundColor: "gray.700",
                  }}
                  _web={{
                    shadow: 2,
                    borderWidth:
                      MySubscriptions.data?.mySubscriptions.length > 0 ? 5 : 0,
                  }}
                  _light={{
                    backgroundColor: "gray.50",
                  }}
                >
                  <Box>
                    <Center
                      bg="violet.500"
                      _dark={{
                        bg: "violet.400",
                      }}
                      _text={{
                        color: "warmGray.50",
                        fontWeight: "700",
                        fontSize: "xs",
                      }}
                      position="absolute"
                      bottom="0"
                      px="3"
                      py="1.5"
                    >
                      {data?.item?.name}
                    </Center>
                  </Box>
                  <Stack p="4" space={3}>
                    <Stack space={2}>
                      <View
                        style={{
                          flexDirection: "row",
                          flex: 1,
                        }}
                      >
                        <Heading size="md" ml="-1">
                          {data?.item?.name}
                        </Heading>
                      </View>
                      <Center>
                        <Heading fontSize="4xl">
                          {data?.item?.priceYearly === 0
                            ? "Free"
                            : priceMonthly === true
                            ? `$${data?.item.priceMonthly}`
                            : `$${data?.item?.priceYearly}`}
                        </Heading>
                        <Text>
                          {priceMonthly === true
                            ? "Billed Monthly"
                            : "Billed Yearly"}
                        </Text>
                      </Center>
                      <Text
                        fontSize="xs"
                        _light={{
                          color: "violet.500",
                        }}
                        _dark={{
                          color: "violet.400",
                        }}
                        fontWeight="500"
                        ml="-0.5"
                        mt="-1"
                      >
                        {data?.item?.GB} GBs and{" "}
                        {data?.item?.maxShares >= 0
                          ? data?.item?.maxShares
                          : "Unlimited"}{" "}
                        Shared Users
                      </Text>
                    </Stack>
                    {/* <Text fontWe`ight="400">{data?.item?.description}</Text> */}
                    <Button
                      backgroundColor={
                        MySubscriptions.data?.mySubscriptions
                          .filter((a) => a.status === "ACTIVE")
                          .some((j) => j.planID === data?.item.id) &&
                        selectedPlan === undefined
                          ? "success.500"
                          : selectedPlan?.id === data?.item?.id
                          ? "success.500"
                          : data?.item?.trial === true
                          ? "blue.500"
                          : "primary.500"
                      }
                      onPress={() => {
                        apollo
                          .mutate({
                            mutation: changePlan,
                            variables: {
                              planId: data?.item?.id,
                              monthly: priceMonthly ?? false,
                            },
                          })
                          .then((res) => {
                            window.location = res.data.checkoutSubscription.checkOutURL                            
                          })
                      }}
                      disabled={MySubscriptions.data?.mySubscriptions
                        .filter((a) => a.status === "ACTIVE")
                        .some((j) => j.planID === data?.item.id)}
                    >
                      {MySubscriptions.data?.mySubscriptions
                        .filter((a) => a.status === "ACTIVE")
                        .some((j) => j.planID === data?.item.id)
                        ? "Current Plan"
                        : data?.item?.trial === true
                        ? "Start 14 Day Trial"
                        : "Start Now"}
                    </Button>
                  </Stack>
                </Box>
              </View>
            )}
          ></FlatList>
        </View>
      </View>
    </ScrollView>
  );
}

export default NewCheckout;
