import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  fromPromise,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import * as Linking from "expo-linking";
import {
  extendTheme,
  HStack,
  NativeBaseProvider,
  Switch,
  Text,
  VStack,
} from "native-base";
import React from "react";
import { Platform } from "react-native";
import { refreshToken } from "./Auth";
import Core from "./screens/Core";
import Login from "./screens/Login";
import SignUp from "./screens/SignUp";
import { initializeApp } from "firebase/app";
import { Link } from "native-base";

// Optionally import the services that you want to use
// import {...} from "firebase/auth";
// import {...} from "firebase/database";
// import {...} from "firebase/firestore";
// import {...} from "firebase/functions";
// import {...} from "firebase/storage";
import AccountVerify from "./screens/AccountVerify";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyA3B9FL1Nor_6ZV_su_thA8Zi5_LB5yzew",
  authDomain: "my-family-movie.firebaseapp.com",
  projectId: "my-family-movie",
  storageBucket: "my-family-movie.appspot.com",
  messagingSenderId: "102632806390",
  appId: "1:102632806390:web:70e60a5917b1a23a0219d7",
  measurementId: "G-07TVHG6FXJ",
};

const app = initializeApp(firebaseConfig);
// Define the config
const config = {
  config: {
    useSystemColorMode: false,
    initialColorMode: "dark",
  },
  colors: {
    primary: {
      "50": "#ffd9b9",
      "100": "#ffc491",
      "200": "#ffaf69",
      "300": "#ff9a41",
      "400": "#ff851b",
      "500": "#f7790a",
      "600": "#db6e0e",
      "700": "#bf6313",
      "800": "#a45816",
      "900": "#8a4e18",
    },
  },
};
const Stack = createStackNavigator();

// extend the theme
export const theme = extendTheme(config);
// const
type MyThemeType = typeof theme;
declare module "native-base" {
  interface ICustomTheme extends MyThemeType {}
}

const httpLink = new HttpLink({
  uri: __DEV__ ? "http://localhost:4000" : "https://api.myfamilymovies.com",
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  let token = localStorage.getItem("idToken");
  console.log("token read");
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: "Bearer " + token,
    },
  }));

  return forward(operation);
});
const retryLink = new RetryLink({
  delay: { initial: 300, max: Infinity },
  attempts: { max: 5 },
});
const getNewToken = async () => {
  try {
    const newId = await refreshToken();
    if (newId.id_token !== undefined && newId.refresh_token !== undefined) {
      localStorage.setItem("refreshToken", newId.refresh_token);
      localStorage.setItem("idToken", newId.id_token);
      return newId.id_token;
    } else {
      localStorage.removeItem("idToken");
      localStorage.removeItem("refreshToken");
      navigationRef.current.navigate("Login");
    }
    return;
  } catch (error) {
    console.log(error);
  }
};

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        console.log(err.extensions.code);
        switch (err.extensions.code) {
          case "INTERNAL_SERVER_ERROR":
            return fromPromise(
              getNewToken().catch((error) => {
                return;
              })
            )
              .filter((value) => Boolean(value))
              .flatMap((accessToken) => {
                const oldHeaders = operation.getContext().headers;
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${accessToken}`,
                  },
                });
                return forward(operation);
              });
        }
      }
    }
  }
);

const activityMiddleware = new ApolloLink((operation, forward) => {
  // add the recent-activity custom header to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "recent-activity": localStorage.getItem("lastOnlineTime") || null,
    },
  }));

  return forward(operation);
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  name: `${Platform.OS.toUpperCase()}-MyFamilyMovies-APP`,
  link: from([errorLink, authMiddleware, httpLink, activityMiddleware]),
});

const linking = {
  prefixes: [Linking.createURL("/"), "https://app.myfamilymovies.com"],
  config: {
    screens: {
      Core: {
        initialRouteName: "Home",
        screens: {
          Home: "Core",
          CreateAlbum: "Core/CreateAlbum",
          Album: "Core/Album",
          Watch: "Core/Watch",
          CreateVideo: "Core/CreateVideo",
          Subscription: "Core/Subscription",
          MyAccount: "MyAccount",
          Upgrade: "Core/Upgrade",
          EditVideo: "Core/EditVideo",
          Tag: "Core/Tag",
          ShareCenter: "Core/ShareCenter",
          Tags: "Core/Tags",
        },
      },
      AccountVerify: "AccountVerify/:token",
      SignUp: "SignUp",
    },
  },
};
const navigationRef = React.createRef<NavigationContainerRef>();
console.log(
  localStorage.getItem("idToken") !== null ||
    localStorage.getItem("refreshToken") !== null
);
export default function App() {
  return (
    <ApolloProvider client={client}>
      <NativeBaseProvider theme={theme}>
        <NavigationContainer ref={navigationRef} linking={linking}>
          <Stack.Navigator
            initialRouteName={
              localStorage.getItem("idToken") !== null ||
              localStorage.getItem("refreshToken") !== null
                ? "Core"
                : "Login"
            }
          >
            <Stack.Screen
              name="Login"
              component={Login}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="AccountVerify"
              component={AccountVerify}
              options={{ headerShown: false, presentation: "modal" }}
            />
            <Stack.Screen
              name="SignUp"
              component={SignUp}
              options={{ headerShown: false }}
            />
            <Stack.Screen
              name="Core"
              component={Core}
              options={{ headerShown: false }}
            />
          </Stack.Navigator>
        </NavigationContainer>
        <VStack
          _dark={{ bg: "blueGray.900" }}
          _light={{ bg: "blueGray.50" }}
          px={4}
          alignItems={"center"}
        >
          <Text fontSize={"xs"}>Made with ❤️ in ND, US</Text>
          <Text fontSize={"xs"}>
            © 2023 All rights reserved{" "}
            <Link
              href={
                "https://app.termly.io/document/privacy-policy/0c40f250-cefe-454e-bf86-5af106f41cc9"
              }
            >
              Privacy
            </Link>{" "}
            <Link
              href={
                "https://app.termly.io/document/terms-of-service/6f6da05d-f725-41a1-9c72-675a60d8b1b2"
              }
            >
              Terms And Condition
            </Link>{" "}
            <Link
              href={
                "https://app.termly.io/document/eula/d27f96c9-df81-4b27-a512-b8ffc435edef"
              }
            >
              EULA
            </Link>
          </Text>
        </VStack>
      </NativeBaseProvider>
    </ApolloProvider>
  );
}

// Color Switch Component
function ToggleDarkMode() {
  return (
    <HStack space={2} alignItems="center">
      <Text>Dark</Text>
      <Switch
        isChecked={colorMode === "light"}
        onToggle={toggleColorMode}
        aria-label={
          colorMode === "light" ? "switch to dark mode" : "switch to light mode"
        }
      />
      <Text>Light</Text>
    </HStack>
  );
}
