import qs from "qs";
export const getTokenPassword = (
  username: string,
  password: string
): Promise<{ idToken: string; refreshToken: string }> => {
  return new Promise(async (resolve, reject) => {
    const url =
      "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyA3B9FL1Nor_6ZV_su_thA8Zi5_LB5yzew";
    const data = (
      await fetch(url, {
        method: "post",
        body: JSON.stringify({
          email: username,
          password: password,
          returnSecureToken: true,
        }),
      })
    )
      .json()
      .catch((e) => {
        reject(e);
      });
    resolve(data);
  });
};
export const signUpUser = (
  username: string,
  password: string
): Promise<{ idToken: string; refreshToken: string }> => {
  return new Promise(async (resolve, reject) => {
    try {
      const url =
        "https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyA3B9FL1Nor_6ZV_su_thA8Zi5_LB5yzew";
      const data = (
        await fetch(url, {
          method: "post",
          body: JSON.stringify({
            email: username,
            password: password,
            returnSecureToken: true,
          }),
        })
      ).json();
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};
export const refreshToken = (): Promise<{
  id_token: string;
  refresh_token: string;
}> => {
  return new Promise(async (resolve, reject) => {
    const url =
      "https://securetoken.googleapis.com/v1/token?key=AIzaSyA3B9FL1Nor_6ZV_su_thA8Zi5_LB5yzew";
    const data = (
      await fetch(url, {
        method: "post",
        body: JSON.stringify({
          grant_type: "refresh_token",
          refresh_token: localStorage.getItem("refreshToken"),
          returnSecureToken: true,
        }),
      })
    ).json();
    resolve(data);
  });
};
