import { useWindowDimensions } from "react-native";
import React from "react";
import { Button, FlatList, HStack, Text, View } from "native-base";
import { Tags } from "../../types";
import { getWidthDividedBy } from "../../helper";
import { useNavigation } from "@react-navigation/native";

const TagListView = (props: { tags: Tags[] }) => {
  const { tags } = props;
  const width = useWindowDimensions().width;
  const column = getWidthDividedBy(width);
  const navigation = useNavigation();
  if (!tags) return <View></View>;
  //filer out duplicates
  const uniqueTags = tags.filter(
    (v, i, a) => a.findIndex((t) => t.name === v.name) === i
  );
  return (
    <View>
      {uniqueTags.length > 0 && (
        <HStack>
          {/*explan no tags*/}
          <Text fontSize="2xl">No Tags</Text>
        </HStack>
      )}
      <FlatList
        key={column.toString()}
        keyExtractor={(item, i) => item.id + i}
        width="100%"
        numColumns={column * 4}
        showsHorizontalScrollIndicator={false}
        //   contentContainerStyle={{ alignItems: 'center' }}
        onEndReached={() => {}}
        data={uniqueTags.filter((t, i) => i < column * 4)}
        renderItem={({ item }) => (
          <View>
            <Button
              marginX={1}
              variant={"outline"}
              size="sm"
              onPress={() => {
                navigation.navigate("Tag", { tagName: item.name });
              }}
            >
              {item.name}
            </Button>
          </View>
        )}
      ></FlatList>
    </View>
  );
};

export default TagListView;
