import React, { useEffect, useState } from "react";
import { Text, Link, HStack, Center, View, Button, Alert } from "native-base";
import { useNavigation, useRoute } from "@react-navigation/native";
import { gql, useApolloClient } from "@apollo/client";
export default function AccountVerify() {
  const route = useRoute();
  const apollo = useApolloClient();
  const [hasTokenOrError, setHasTokenOrError] = useState(false);
  const [sent, setSent] = useState();
  useEffect(() => {
    //@ts-ignore
    if (route.params?.token !== undefined) {
      //@ts-ignore
      console.log("verify account", route.params?.token);
      apollo
        .mutate({
          mutation: gql`
            mutation ($token: String!) {
              verifyAccount(token: $token)
            }
          `,
          variables: {
            //@ts-ignore
            token: route.params?.token,
          },
        })
        .then((res) => {
          if (res.data.verifyAccount == true) {
            window.location.href = "/core";
          }
          setHasTokenOrError(res.data.verifyAccount);
        })
        .catch((err) => {
          console.log(err);
          setHasTokenOrError(false);
        });
      //@ts-ignore
      console.log(route.params?.token);
      setHasTokenOrError(true);
    }
  }, [route]);

  return (
    <View
      flex={1}
      justifyContent="center"
      alignItems="center"
      _dark={{ bg: "blueGray.900" }}
      _light={{ bg: "blueGray.50" }}
      px={4}
    >
      {hasTokenOrError ? (
        <Text fontSize="3xl">Account Verified</Text>
      ) : (
        <View>
          <Text fontSize="3xl">Account Verification Pending</Text>
          {/*resend verification email*/}
          <Button
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
            bgColor={sent ? "gray.400" : "blue.500"}
            disabled={sent}
            onPress={() => {
              // resend verification email
              console.log("resend verification email");
              setSent(true);
              apollo
                .mutate({
                  mutation: gql`
                    mutation {
                      resendVerification
                    }
                  `,
                })
                .then((res) => {
                  console.log(res);
                });
            }}
          >
            Resend Verification Email
          </Button>
          {sent && (
            <Alert status={"success"} fontSize="xl" style={{ marginTop: 14 }}>
              <Text color="coolGray.800">Verification Email Sent</Text>
            </Alert>
          )}
        </View>
      )}
    </View>
  );
}
