import React from "react";
import { Linking, TouchableOpacity } from "react-native";
import {
  Avatar,
  Button,
  View,
  Center,
  Heading,
  HStack,
  Popover,
  VStack,
  Text,
} from "native-base";
import { useNavigation } from "@react-navigation/native";
import { gql, useApolloClient, useQuery } from "@apollo/client";
import { GET_HOME } from "../Query/Account";
import { useEffect } from "react";
import { Albums, Tags, subscription } from "../types";
import AlbumsList from "./Components/AlbumsList";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import TagListView from "./Components/TagListView";

export default function Home() {
  const navigation = useNavigation();
  const MyAccount = useQuery<
    {
      myAccount: {
        firstName: string;
        lastName: string;
        customerId: string;
        verified: boolean;
        billingPortalURL: string;
      };
      mySubscriptions: subscription[];
      myAlbums: Albums[];
      getTags: Tags[];
    },
    never
  >(GET_HOME, {});
  const apollo = useApolloClient();
  return (
    <VStack
      _dark={{ bg: "blueGray.900" }}
      _light={{ bg: "blueGray.50" }}
      px={0}
      flex={1}
      space={5}
    >
      <AlbumsList
        headerContent={
          <>
            <HStack>
              <Heading padding={2} size="xl">
                My Family Movies
              </Heading>
              <MaterialCommunityIcons
                onPress={() =>
                  alert(
                    "To create a new movie, go to the album you'd like to add it to and click the plus icon."
                  )
                }
                style={{ position: "absolute", right: 60, top: 20 }}
                flex={1}
                size={32}
                color={"white"}
                name="movie-plus-outline"
              ></MaterialCommunityIcons>
              <View style={{ position: "absolute", right: 10, top: 10 }}>
                <Popover
                  trigger={(triggerProps) => {
                    return (
                      <TouchableOpacity {...triggerProps}>
                        <Avatar padding={2}>
                          {MyAccount.data
                            ? `${MyAccount.data?.myAccount?.firstName
                                ?.charAt(0)
                                .toUpperCase()}${MyAccount.data?.myAccount?.lastName
                                ?.charAt(0)
                                .toUpperCase()}`
                            : "..."}
                        </Avatar>
                      </TouchableOpacity>
                    );
                  }}
                >
                  <Popover.Content accessibilityLabel="Account Options" w="56">
                    {/* <Popover.Arrow /> */}
                    <Popover.CloseButton />
                    <Popover.Header>Your Account</Popover.Header>
                    <Popover.Body>
                      {(MyAccount.data?.mySubscriptions?.filter(
                        (sub) => sub.status === "ACTIVE"
                      )?.length ?? 0) > 0 ? (
                        <View>
                          <Button
                            variant={"link"}
                            onPress={() => {
                              navigation.navigate("MyAccount");
                            }}
                          >
                            <Text>Manage Account</Text>
                          </Button>
                          <Button
                            variant={"link"}
                            onPress={() => {
                              //open profile billingPortalURL in new tab
                              console.log(
                                MyAccount.data?.myAccount?.billingPortalURL
                              );
                              window.open(
                                MyAccount.data?.myAccount?.billingPortalURL,
                                "_blank"
                              );
                            }}
                          >
                            <Text>Manage Subscription</Text>
                          </Button>
                        </View>
                      ) : (
                        <Button
                          variant={"link"}
                          onPress={() => {
                            navigation.navigate("Subscription");
                          }}
                        >
                          <Text>Purchase Subscription</Text>
                        </Button>
                      )}
                    </Popover.Body>
                    <Popover.Footer justifyContent="flex-end">
                      <Button.Group space={2}>
                        <Button
                          colorScheme="danger"
                          onPress={() => {
                            localStorage.removeItem("idToken");
                            localStorage.removeItem("refreshToken");
                            apollo.resetStore();
                            apollo.clearStore();
                            navigation.navigate("Login");
                            window.location.reload();
                          }}
                        >
                          Log Out
                        </Button>
                      </Button.Group>
                    </Popover.Footer>
                  </Popover.Content>
                </Popover>
              </View>
            </HStack>
            <Heading>Tags</Heading>
            <TagListView tags={MyAccount.data?.getTags} />
          </>
        }
        albums={[...(MyAccount.data?.myAlbums ?? [])].sort((a, b) =>
          a.title.localeCompare(b.title)
        )}
      />
    </VStack>
  );
}
