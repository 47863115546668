import { gql } from '@apollo/client'
export const CREATE_ALBUM = gql`
  mutation CreateAlbum(
    $title: String!
    $description: String
    $allowFastDownloads: Boolean
  ) {
    createAlbum(
      title: $title
      description: $description
      allowFastDownloads: $allowFastDownloads
    ) {
      id
      userID
      createdAt
      updatedAt
      title
      description
    }
  }
`

export const SHARE_ALBUM = gql`
  mutation ShareAlbum($albumIDs: [String]!, $emails: [String]) {
    shareAlbum(albumIDs: $albumIDs, emails: $emails) {
      id
    }
  }
`
export const UNSHARE_ALBUM = gql`
  mutation UnShareAlbum($sid: String!) {
    unShareAlbum(id: $sid) {
      id
    }
  }
`
export const GET_ALBUM_SHARES = gql`
  query GetShares($albumId: String!) {
    album(id: $albumId) {
      sharedUsers {
        id
        email
      }
    }
  }
`
export const MY_ALBUMS = gql`
  query MyAlbums {
    myAlbums {
      id
      title
      owner {
        firstName
        lastName
      }
      tags {
        id
        name
      }
    }
  }
`

export const GET_ALBUM = gql`
  query GetAlbum($id: String!) {
    album(id: $id) {
      id
      userID
      createdAt
      updatedAt
      title
      description
      owner {
        firstName
        lastName
      }
      videos {
        id
        title
        description
        status
        videoLinks {
          thumbnailURL
          previewURL
          videoURL
          downloadURL
        }
        owner {
          firstName
          lastName
        }
        createdAt
        updatedAt
      }
    }
  }
`
