import { gql } from '@apollo/client'
export const VIEW_VIDEO = gql`
  query ViewVideo($id: String!) {
    video(id: $id) {
      id
      userID
      createdAt
      albumID
      updatedAt
      title
      rating
      tags {
        id
        name
      }
      description
      videoLinks {
        thumbnailURL
        videoURLMobile
        previewURL
        videoURL
        downloadURL
      }
    }
  }
`
export const CREATE_VIDEO = gql`
  mutation CreateVideo(
    $title: String!
    $description: String
    $albumId: String!
    $ext: String!
    $size: Int!
    $tagNames: [String!]
  ) {
    createVideo(
      title: $title
      description: $description
      ext: $ext
      size: $size
      albumID: $albumId
      tagNames: $tagNames
    ) {
      status
      message
      video {
        album {
          title
        }
        id
        title
        description
        uploadURL
      }
    }
  }
`

export const UNTAG_VIDEO = gql`
  mutation unTag($video: String!, $tag: String!) {
    removeTagFromVideo(video: $video, tag: $tag) {
      id
    }
  }
`
export const TAG_VIDEO = gql`
  mutation AddTagToVideo($videoId: String!, $tagName: String!) {
    addTagToVideo(videoID: $videoId, tagName: $tagName) {
      id
    }
  }
`
export const GET_TAGS = gql`
  query GetTags {
    getTags {
      id
      name
    }
  }
`
export const DELETE_VIDEO = gql`
  mutation DeleteVideo($deleteVideoId: String!) {
    deleteVideo(id: $deleteVideoId) {
      id
    }
  }
`
export const RATE_VIDEO = gql`
  mutation Mutation($videoId: String!, $rating: Int!) {
    rateVideo(videoID: $videoId, rating: $rating) {
      id
      rating
      ratings {
        id
      }
    }
  }
`
