import React, { useEffect, useMemo, useState } from 'react'
import {
  View,
  Text,
  Image,
  Heading,
  HStack,
  IconButton,
  Button,
  AlertDialog,
  useColorMode,
  TextField,
} from 'native-base'
import VideoList from './Components/VideosList'
import {
  GET_ALBUM,
  GET_ALBUM_SHARES,
  SHARE_ALBUM,
  UNSHARE_ALBUM,
} from '../Query/Album.graphql'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { useNavigation, useRoute } from '@react-navigation/native'
import Ionicons from '@expo/vector-icons/Ionicons'
import { getWidthDividedBy } from '../helper'
import SharedAlbum from './Components/ShareAlbum'
import { Video } from '../types'

export default function Album() {
  const route = useRoute()
  const AlbumData = useQuery(GET_ALBUM, {
    variables: { id: route.params.albumId, limit: 30, offset: 0 },
    options: { fetchPolicy: 'cache-and-network' },
    pollInterval: 60000,
  })
  const [editShare, setEditShare] = useState(false)
  const AlbumDataShare = useQuery<{
    album: {
      sharedUsers: {
        id: string
        email: string
      }[]
    }
  }>(GET_ALBUM_SHARES, {
    variables: { albumId: route.params.albumId },
  })
  const [inviteEmail, setInviteEmail] = useState('')
  const ref = React.useRef()
  const navigation = useNavigation()
  const [viewMode, setViewMode] = useState(0)
  const apolloClient = useApolloClient()
  const column = useMemo(() => {
    return getWidthDividedBy()
  }, [window.window.innerWidth])
  const [showShare, setShowShare] = useState(false)
  const [ShareAlbum, { data, loading, error }] = useMutation(SHARE_ALBUM, {})
  const [UnShareAlbum, never] = useMutation(UNSHARE_ALBUM, {})

  useEffect(() => {
    navigation.setOptions({
      title: `Album: ${AlbumData.data?.album?.title}`,
      headerRight: () => (
        <Ionicons
          onPress={() => {
            setShowShare(true)
          }}
          size={30}
          color={'#f7790a'}
          style={{ marginRight: 10 }}
          name={'people-circle-outline'}
        />
      ),
    })
  }, [AlbumData.data])
  if (AlbumData.loading) return <Text>Loading...</Text>
  return (
    <View
      padding={'2'}
      style={{ height: '100%' }}
      _dark={{ bg: 'blueGray.900' }}
    >
      {/* <Image
        style={{ height: '15%' }}
        source={{
          uri:
            'https://media.istockphoto.com/id/992051544/photo/fargo-north-dakota.jpg?s=612x612&w=0&k=20&c=tpsnUxFGtljoqH615EtUH4xdVGvYKE6quO3eng4lkt8=',
        }}
      /> */}
      {viewMode != 2 && (
        <>
          <AlertDialog
            leastDestructiveRef={ref}
            isOpen={showShare}
            onClose={() => {
              setShowShare(false)
            }}
          >
            <AlertDialog.Content>
              <AlertDialog.CloseButton />
              <AlertDialog.Header>{'Share Album'}</AlertDialog.Header>
              <AlertDialog.Body>
                {AlbumDataShare.data?.album.sharedUsers.map((sa) => {
                  return (
                    <View flex={1} flexDirection={'row'} paddingBottom={1}>
                      <Text fontSize={'md'}>{sa.email}</Text>
                      <Ionicons
                        onPress={async () => {
                          await UnShareAlbum({
                            variables: {
                              sid: sa.id,
                            },
                          })
                          await AlbumDataShare.refetch()
                        }}
                        size={24}
                        style={{
                          paddingLeft: 8,
                          position: 'absolute',
                          right: 0,
                        }}
                        color={'white'}
                        name={'ios-trash-outline'}
                      ></Ionicons>
                    </View>
                  )
                })}
                <View marginY={1} />
                {editShare ? (
                  <>
                    <TextField
                      placeholder="Email"
                      onChangeText={(text) => {
                        console.log(text)
                        setInviteEmail(text)
                      }}
                      keyboardType="email-address"
                    />
                    <Button
                      disabled={
                        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(inviteEmail) ==
                        false
                      }
                      backgroundColor={
                        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(inviteEmail)
                          ? 'success.500'
                          : 'coolGray.500'
                      }
                      onPress={() => {
                        console.log('test')
                        console.log({
                          albumId: route.params.albumId,
                          emails: [inviteEmail],
                        })
                        ShareAlbum({
                          variables: {
                            albumIDs: [route.params?.albumId!],
                            emails: [inviteEmail],
                          },
                        })
                        setInterval(() => {
                          AlbumDataShare.refetch()
                        }, 2000)
                        setEditShare(false)
                        setInviteEmail('')
                      }}
                    >
                      <Ionicons name="paper-plane-outline" size={22} />
                    </Button>
                  </>
                ) : (
                  <Button
                    onPress={() => {
                      setEditShare(true)
                    }}
                  >
                    <Text>{'Add User'}</Text>
                  </Button>
                )}
              </AlertDialog.Body>
              <AlertDialog.Footer>
                <Button.Group space={2}>
                  <Button
                    variant="unstyled"
                    colorScheme="coolGray"
                    onPress={() => {
                      setShowShare(false)
                    }}
                    ref={ref}
                  >
                    Cancel
                  </Button>
                  <Button
                    colorScheme="success"
                    onPress={() => {
                      setShowShare(false)
                    }}
                  >
                    Save
                  </Button>
                </Button.Group>
              </AlertDialog.Footer>
            </AlertDialog.Content>
          </AlertDialog>
          <HStack>
            <Heading padding={2} size="lg">
              {'Videos'}
              <Ionicons
                onPress={() =>
                  navigation.navigate('CreateVideo', {
                    albumId: route.params.albumId,
                  })
                }
                flex={1}
                style={{ position: 'absolute', right: 10 }}
                size={32}
                name="md-add-outline"
              ></Ionicons>
            </Heading>
          </HStack>
          {AlbumData.data.album.videos.length === 0 ? (
            <Text>No Videos</Text>
          ) : (
            <VideoList
              height={40}
              videos={[
                ...(AlbumData.data.album.videos ?? []),
              ].sort((a: Video, b: Video) => a.title.localeCompare(b.title))}
            />
          )}
          {/* {viewMode == 0 ? (
            <Button
              marginX={5}
              marginY={2}
              onPress={() => {
                setViewMode(viewMode == 1 ? 0 : 1)
              }}
            >
              Show More Videos
            </Button>
          ) : (
            <Button
              marginX={5}
              marginY={2}
              onPress={() => {
                setViewMode(0)
              }}
            >
              Show Both Photos And Videos
            </Button>
          )} */}
        </>
      )}
      {/* {viewMode != 1 && (
        <>
          <HStack>
            <Heading padding={2} size="lg">
              Photos{' '}
              <Ionicons
                onPress={() =>
                  navigation.navigate('CreatePhoto', {
                    albumId: route.params.albumId,
                  })
                }
                flex={1}
                style={{ position: 'absolute', right: 10 }}
                size={32}
                name="md-add-outline"
              ></Ionicons>
            </Heading>
          </HStack>
          <VideoList videos={AlbumData.data.album.videos ?? []} />
          {viewMode == 2 ? (
            <Button
              marginX={5}
              marginY={2}
              onPress={() => {
                setViewMode(0)
              }}
            >
              Show Both Photos And Videos
            </Button>
          ) : (
            <Button
              marginX={5}
              marginY={2}
              onPress={() => {
                setViewMode(viewMode == 2 ? 0 : 2)
              }}
            >
              Show Photos
            </Button>
          )}
        </>
      )} */}
    </View>
  )
}
