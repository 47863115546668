import { gql } from "@apollo/client";
import { AvailablePlans } from "../types/index.d";

export const GET_HOME = gql`
  query HomePage {
    myAccount {
      firstName
      lastName
      customerId
      verified
      billingPortalURL
      usedStorage
    }
    getTags {
      id
      name
    }
    myAlbums {
      id
      title
      description
      coverPhoto {
        url
      }
      owner {
        firstName
        lastName
      }
    }
    mySubscriptions {
      id
      userID
      userAccepted
      plan
      endAt
      maxStorage
      planID
      status
      planType {
        id
        trial
      }
    }
  }
`;

export const GET_SUBSCRIPTIONS = gql`
  query UsersSubscriptions {
    myAccount {
      userID
      id
      firstName
      email
      verified
      lastName
    }
    mySubscriptions {
      id
      userID
      userAccepted
      plan
      endAt
      planID
      status
    }
    myAlbums {
      id
    }
  }
`;

export const AVAILABLE_SUBSCRIPTIONS = gql`
  query AvailablePlans {
    availablePlans {
      id
      plan
      name
      trial
      priceYearly
      priceMonthly
      paypalPlanMonthlyTest
      paypalPlanYearlyTest
      paypalPlanMonthlyProd
      paypalPlanYearlyProd
      GB
      maxShares
      description
    }
  }
`;

export const CAPTUREPAYMENT = gql`
  mutation createOrder($orderData: OrderInput!) {
    createOrder(orderData: $orderData)
  }
`;

export const planLookup = (planId: string) => {
  if (planId === "FREE-5-14DAYS") {
    return "Free 5 GB for 14 Days Trial";
  }
};

export const changePlan = gql`
  mutation Mutation($planId: String!, $monthly: Boolean!) {
    checkoutSubscription(planId: $planId, monthly: $monthly) {
      message
      status
      checkOutURL
    }
  }
`;
