import { Center, FlatList, HStack, IconButton, Image } from 'native-base'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { View, Text } from 'native-base'
import { Video } from '../../types'
import { TouchableOpacity, useWindowDimensions } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import Ionicons from '@expo/vector-icons/Ionicons'
import { MaterialIcons } from '@expo/vector-icons'
import { getWidthDividedBy } from '../../helper'

export default function VideoList(props: {
  videos?: Video[]
  height: number | string
}) {
  const navigation = useNavigation()
  const [scrollIndex, setScrollIndex] = useState(0)
  const listRef = React.useRef<FlatList<Albums> | null>(null)
  const test = useRef<FlatList>()
  const width = useWindowDimensions().width

  const column = getWidthDividedBy(width)
  return (
    <FlatList
      flex={1}
      key={column.toString()}
      keyExtractor={(item, i) => item.id + i}
      width="100%"
      numColumns={column}
      showsHorizontalScrollIndicator={false}
      ref={listRef}
      //   contentContainerStyle={{ alignItems: 'center' }}
      onEndReached={() => {}}
      data={props.videos}
      renderItem={(item) => (
        <TouchableOpacity
          flex={4}
          onPress={() => {
            console.log(item.item.id)
            navigation.navigate('Watch', { videoId: item.item.id })
          }}
        >
          <View
            margin={'5px'}
            borderRadius={10}
            height={240}
            width={(window.window.innerWidth - 13 * column) / column}
            backgroundColor={'blueGray.700'}
          >
            {item.item.status === 'CDN-FINISHED' ? (
              <Image
                resizeMode={'cover'}
                borderRadius={10}
                alt={'video thumbnail'}
                style={{ width: '100%', height: '100%' }}
                source={{
                  uri: item.item.videoLinks.thumbnailURL,
                }}
              />
            ) : (
              <Center
                flex={1}
                marginBottom={8}
                style={{ backgroundColor: 'blueGray.600' }}
              >
                <Text
                  fontSize="lg"
                  color={'white'}
                  textTransform={'full-size-kana'}
                >
                  {item.item.status === 'pending'
                    ? 'NOT UPLOADED'
                    : item.item.status === 'BLOB-UPLOADED'
                    ? 'PROCESSING'
                    : 'PROCESSING'}
                </Text>
              </Center>
            )}
            <View
              borderBottomRadius={10}
              paddingX={2}
              width="100%"
              backgroundColor={'white'}
              style={{ position: 'absolute', bottom: 0 }}
              opacity={0.8}
            >
              <Text color={'black'} numberOfLines={1} flex>
                {item.item.title}
              </Text>
              <Text color={'black'}>
                Shared By: {item.item.owner.firstName}
              </Text>
              <MaterialIcons
                flex={1}
                style={{
                  backgroundColor: 'lightgrey',
                  position: 'absolute',
                  borderRadius: 16,
                  right: 40,
                  top: 5,
                }}
                onPress={() => {
                  const link = document.createElement('a')
                  link.download = 'test.mp4'
                  console.log(item.item.videoLinks.downloadURL)
                  link.href = item.item.videoLinks.downloadURL
                  link.style.display = 'none'
                  link.click()
                }}
                size={32}
                name="file-download"
              ></MaterialIcons>
              <MaterialIcons
                onPress={() =>
                  navigation.navigate('EditVideo', { id: item.item.id })
                }
                flex={1}
                style={{
                  backgroundColor: 'lightgrey',
                  position: 'absolute',
                  borderRadius: 16,
                  right: 5,
                  top: 5,
                }}
                size={32}
                name="more-vert"
              ></MaterialIcons>
            </View>
          </View>
        </TouchableOpacity>
      )}
    ></FlatList>
  )
}
