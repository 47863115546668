import React, { useEffect, useState } from "react";
import {
  Text,
  Link,
  HStack,
  Center,
  Heading,
  Switch,
  useColorMode,
  NativeBaseProvider,
  extendTheme,
  VStack,
  Box,
  TextField,
  Button,
  Input,
} from "native-base";
import auth from "@react-native-firebase/auth";
import { useNavigation } from "@react-navigation/native";
import { getTokenPassword } from "../Auth";
import { useApolloClient } from "@apollo/client";

export default function Login() {
  const navigation = useNavigation();
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const apollo = useApolloClient();
  return (
    <Center
      _dark={{ bg: "blueGray.900" }}
      _light={{ bg: "blueGray.50" }}
      px={4}
      flex={1}
    >
      <VStack space={5} alignItems="center">
        <Heading size="lg">Welcome to My Family Movies</Heading>
        <Heading size="md">Login and Start Watching</Heading>

        <Input
          color={"white"}
          type={"email"}
          size="2xl"
          placeholder="E-Mail"
          onChangeText={(e) => setLogin(e)}
        ></Input>
        <Input
          color={"white"}
          type={"password"}
          size="2xl"
          placeholder="Password"
          onChangeText={(e) => setPassword(e)}
        ></Input>

        <Button
          onPress={() => {
            getTokenPassword(login, password).then((auth) => {
              console.log(auth);
              localStorage.setItem("idToken", auth.idToken);
              localStorage.setItem("refreshToken", auth.refreshToken);
              setTimeout(() => {
                apollo.refetchQueries({
                  include: "all",
                });
              }, 1000);
              navigation.navigate("Core");
            });
          }}
        >
          Sign In
        </Button>
        <Button
          variant="link"
          onPress={async () => navigation.navigate("SignUp")}
        >
          Sign Up
        </Button>
      </VStack>
    </Center>
  );
}
