import React from 'react'
import { View, Text } from 'native-base'

export default function BatchShare() {
  return (
    <View>
      <Text>BatchShareScreen</Text>
    </View>
  )
}
