import { useQuery } from '@apollo/client'
import { useNavigation, useRoute } from '@react-navigation/native'
import { View, Text, VStack } from 'native-base'
import React, { useEffect } from 'react'
import { GetTagInfo } from '../Query/Tag'
import { Video } from '../types'
import VideoList from './Components/VideosList'

const TagView = () => {
  const route = useRoute()
  console.log(route.params?.tagName)
  const navigation = useNavigation()
  const MyAccount = useQuery(GetTagInfo, {
    variables: {
      name: route?.params.tagName ?? '',
    },
  })
  useEffect(() => {
    navigation.setOptions({
      title: `Tag: ${route.params.tagName}`,
    })
  }, [])
  console.log(MyAccount.data)
  return (
    <VStack
      _dark={{ bg: 'blueGray.900' }}
      _light={{ bg: 'blueGray.50' }}
      px={0}
      flex={1}
      space={5}
    >
      <VideoList
        height={40}
        videos={[
          ...(MyAccount?.data?.videosByTagName ?? []),
        ].sort((a: Video, b: Video) => a.title.localeCompare(b.title))}
        // title={route.params.tagName}
      />
    </VStack>
  )
}

export default TagView
