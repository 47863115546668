import React, { useEffect, useState } from 'react'
import {
  Text,
  Link,
  HStack,
  Center,
  Heading,
  Switch,
  useColorMode,
  NativeBaseProvider,
  extendTheme,
  VStack,
  Box,
  TextField,
  Button,
  Input,
} from 'native-base'
import { useNavigation } from '@react-navigation/native'
import auth from '@react-native-firebase/auth'
import Profile from '../Services/Profile.service'
import { signUpUser } from '../Auth'
import { useApolloClient } from '@apollo/client'
import { GET_HOME, GET_SUBSCRIPTIONS } from '../Query/Account'

export default function Login() {
  const navigation = useNavigation()
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const client = useApolloClient()
  return (
    <Center
      _dark={{ bg: 'blueGray.900' }}
      _light={{ bg: 'blueGray.50' }}
      px={4}
      flex={1}
    >
      <VStack space={5} alignItems="center">
        <Heading size="lg">Join My Family Movies</Heading>
        <Heading size="md">Setup Account to Start Watching</Heading>

        <Input
          color={'white'}
          type={'username'}
          size="2xl"
          placeholder="Email"
          onChangeText={(e) => setLogin(e)}
        ></Input>
        <Input
          color={'white'}
          type={'password'}
          size="2xl"
          placeholder="Password"
          onChangeText={(e) => setPassword(e)}
        ></Input>
        <Input
          color={'white'}
          size="2xl"
          placeholder="First Name"
          onChangeText={(e) => setFirstName(e)}
        ></Input>
        <Input
          color={'white'}
          size="2xl"
          placeholder="Last Name"
          onChangeText={(e) => setLastName(e)}
        ></Input>
        <Button
          onPress={() => {
            signUpUser(login, password)
              .then(async (auth) => {
                console.log(auth)
                localStorage.setItem('idToken', auth.idToken)
                localStorage.setItem('refreshToken', auth.refreshToken)
                let data = await Profile.signUp(firstName, lastName)
                client.refetchQueries({
                  include: [GET_HOME, GET_SUBSCRIPTIONS],
                })
                navigation.navigate('Core', { screen: 'Subscription' })
              })
              .catch((e) => {
                alert('Error Signing Up, you may already have an account')
                console.log(e)
              })
          }}
        >
          Sign Up
        </Button>
        <Button variant="link" onPress={() => navigation.navigate('Login')}>
          Already Have an Account
        </Button>
      </VStack>
    </Center>
  )
}
