import {
  Button,
  Center,
  Heading,
  HStack,
  Input,
  Spinner,
  Switch,
  TextField,
  Tooltip,
} from 'native-base'
import React, { useState } from 'react'
import { View, Text } from 'native-base'
import { useApolloClient, useMutation } from '@apollo/client'
import { CREATE_ALBUM } from '../Query/Album.graphql'
import { useNavigation } from '@react-navigation/native'
import { GET_HOME } from '../Query/Account'

export default function AlbumCreate() {
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [instantDownload, setInstantDownload] = useState(true)
  const navigation = useNavigation()
  const client = useApolloClient()
  const [CreateAlbum, { data, loading, error }] = useMutation(CREATE_ALBUM)
  if (data) {
    client.refetchQueries({
      include: [GET_HOME],
    })
    navigation.goBack()
  }

  return (
    <View
      padding={'2'}
      style={{ height: '100%', width: '100%' }}
      _dark={{ bg: 'blueGray.900' }}
    >
      <HStack>
        <Center>
          <Text fontSize={'2xl'}>Title:{'   '}</Text>
        </Center>
        <Input
          color={'white'}
          placeholder={'Enter Here'}
          isRequired={true}
          disabled={loading}
          size={'2xl'}
          flex={1}
          onChangeText={(text) => setTitle(text)}
        ></Input>
      </HStack>
      <HStack>
        <Input
          disabled={loading}
          marginTop={2}
          color={'white'}
          placeholder={'Description'}
          size={'2xl'}
          multiline={true}
          numberOfLines={6}
          onChangeText={(text) => setDescription(text)}
          style={{ width: '100%' }}
          width={'100%'}
        ></Input>
      </HStack>
      <HStack marginY={5}>
        <Tooltip label="Downloads will otherwise take 24 to 48 hours to be taken out of archive storage. This may increase storage usage slightly">
          <Text underline>{'Allow Instant Downloads'}</Text>
        </Tooltip>
        <Switch
          value={instantDownload}
          onChange={() => {
            setInstantDownload(!instantDownload)
          }}
          marginLeft={2}
          size="md"
        />
      </HStack>

      <Button
        disabled={loading}
        marginTop={2}
        onPress={() => {
          CreateAlbum({
            variables: {
              title: title,
              description: description,
              allowFastDownloads: instantDownload,
            },
          })
        }}
        endIcon={loading ? <Spinner /> : undefined}
      >
        Create Album
      </Button>
    </View>
  )
}
