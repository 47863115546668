import { client } from '../App'
import { gql } from '@apollo/client'

export const CREATE_USER = gql`
  mutation Mutation($lastName: String!, $firstName: String!) {
    createUser(lastName: $lastName, firstName: $firstName) {
      email
      firstName
      lastName
      userID
      id
      role
      subscriptions {
        id
        userID
        createdAt
        updatedAt
        plan
        status
        renewAt
        canRenew
        endAt
      }
    }
  }
`

function signUp(firstName: string, lastName: string) {
  return new Promise(async (resolve, reject) => {
    let data = await client.mutate({
      mutation: CREATE_USER,
      variables: { firstName: firstName, lastName: lastName },
    })
    console.log(data)
    resolve(data)
  })
}

const Profile = {
  signUp: signUp,
}
export default Profile
